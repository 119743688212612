<template>
  <div>
    <FilterReport title="Filters" :baseReportUrl="baseReportUrl" @clicked-filter="getReport" />
    <div class="mt-1 mb-1 d-flex justify-content-end">
      <b-button-group class="ml-1">
        <b-dropdown right variant="outline-primary">
            <template #button-content>
             <feather-icon icon="DownloadIcon"/>
              {{ $t('globalActions.export') }}
            </template>

            <b-dropdown-item-button @click="exportToPDF">PDF</b-dropdown-item-button>
            <b-dropdown-item-button @click="exportToExcel">Excel</b-dropdown-item-button>

        </b-dropdown>
      </b-button-group>
    </div>
    <!-- Table Container Card -->
    <b-card no-body id="pdfDom">
        <b-card-body class="text-center">
          <b-card-title>{{ companyName }}</b-card-title>
            <div>{{ $t('apps.reportPayment.singular.reportPayment') }}</div>
        </b-card-body>
        <!-- modal pop up  -->
            <b-modal
                  id="modal-xl"
                  ok-only
                  ok-title="OK"
                  centered
                  size="xl"
                  title="Payment Details"
                >
                <b-table-simple id="trial-balance-table"  sticky-header="500px" responsive>
                    <b-thead>
                        <b-tr>
                            <b-th class="text-center" style="min-width: 50px;">No</b-th>
                            <b-th class="text-center">No. Payment</b-th>
                            <b-th class="text-center">No. Invoice</b-th>
                            <b-th class="text-center">Transaction type</b-th>
                            <b-th class="text-center">Bank/Cash</b-th>
                            <b-th class="text-center">Document Payment</b-th>
                            <b-th class="text-center">Payment Amount</b-th>
                        </b-tr>           
                    </b-thead>
                    <b-tbody>
                        <b-tr v-for="(prop, index) in dataDetails" :index="index" :key="prop.id_payment">
                                <b-td>{{ index + 1 }}</b-td>
                                <b-td class="text-center">{{ prop.nomor_referensi }}</b-td>
                                <b-td class="text-center">{{ prop.invoice_no }}</b-td>
                                <b-td class="text-center">{{ prop.transaction_type }}</b-td>
                                <b-td class="text-left ">{{ prop.id_pembayaran }} </b-td>
                                <b-td class="text-center">
                                    <b-link
                                        :to="{ name: 'apps-transactions-purchase-payment-listImage', params: { id: prop.id_payment }}"
                                        class="font-weight-bolder"
                                        >
                                    <span class="font-weight-bolder d-block text-nowrap">
                                       <feather-icon
                                        icon="FileTextIcon"
                                        size="21"
                                      />
                                      </span>
                                    </b-link>
                                </b-td>
                                <b-td class="text-right font-weight-bolder">{{ formatCurrency(prop.pelunasan_uang,false) }}</b-td>
                        </b-tr>
                              <b-td class="text-right font-weight-bolder font-italic" colspan="4">Total</b-td>
                              <b-td class="text-right font-weight-bolder font-italic" colspan="4">{{ formatCurrency(totalsPayment,false) }}</b-td>

                        <b-tr>

                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-modal>

        <!-- table  -->
        <b-table 
          id="refListTable"
          ref="refListTable"
          no-provider-paging
          no-provider-filtering
          :per-page="perPage"
          :current-page="currentPage"
          :filter-included-fields="columnToBeFilter"
          :fields="tableColumns"
          :items="itemLists"
          :filter="searchQuery"
          responsive
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          :busy="isBusy"
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          @filtered="onFiltered"
          class="position-relative"
        >

         <!-- Amount  -->
        <template #cell(nilai_invoice)="{ item }">
          <span class="d-block text-nowrap">

        <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-xl
              variant="outline-primary"
              @click="getDetails(item.invoice_id)"
            >
               {{ formatCurrency(item.nilai_invoice, false) }}
        </b-button>
          </span>
        </template>

          <!-- Amount  -->
        <template #cell(total_dibayar)="{ item }">
          <span class="d-block text-nowrap">
            {{ formatCurrency(item.total_dibayar, false) }}
          </span>
        </template>


         <!-- Amount  -->
        <template #cell(outstanding)="{ item }">
          <span class="d-block text-nowrap">
            {{ formatCurrency(item.outstanding, false) }}
          </span>
        </template>
        
    </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
           <!-- page length -->
           <b-form-group
             label="Per Page"
             label-cols="6"
             label-align="left"
             label-size="sm"
             label-for="sortBySelect"
             class="text-nowrap mb-md-0 mr-1"
           >
             <b-form-select
               id="perPageSelect"
               v-model="perPage"
               size="sm"
               inline
               :options="pageOptions"
             />
           </b-form-group>
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows.length"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
    BLink,
    BTd,
    BTbody,
    BTh,
    BTr,
    BThead,
    BTableSimple,
    VBModal,
    BModal,
    BButton,
    BCard,
    BTable,
    BCardTitle,
    BCardBody,
    BButtonGroup,
    BDropdown,
    BFormGroup,
    BFormSelect,
    BPagination,
    BDropdownItemButton
} from 'bootstrap-vue'
import FilterReport from '@/components/reports/FilterReportPay.vue'
import useListTable from '@/comp-functions/useListTable'
import useFilterReport from '@/comp-functions/useFilterReport'
import { formatCurrency } from '@/utils/formatter'
import Ripple from 'vue-ripple-directive'
import useHttp from '@/comp-functions/useHttp'
import { ref } from '@vue/composition-api'



export default {
  components: {
    BLink,
    BTd,
    BTbody,
    BTh,
    BTr,
    BThead,
    BTableSimple,
    BModal,
    BButton,
    BCard,
    BTable,
    BCardTitle,
    BCardBody,
    FilterReport,
    BButtonGroup,
    BDropdown,
    BFormGroup,
    BFormSelect,
    BPagination,
    BDropdownItemButton
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  computed: {
    companyName () {
      return this.$store.state.user.currentCompany.name
    }
  },
  setup () {
    const tableColumns = [
                // { key: 'no', label: 'No' },
        { key: 'no', label: 'No'},
        { key: 'invoice_no', label: 'No Invoice'},
        { key: 'tanggal_invoice', label: 'date' },
        { key: 'nama_akun', label: 'Vendor' },
        { key: 'nilai_invoice', label: 'Amount', thClass: 'text-center', tdClass: 'text-right'  },
        { key: 'total_dibayar', label: 'Total Payment', thClass: 'text-right', tdClass: 'text-right'  },
        { key: 'outstanding', label: 'Outstanding', thClass: 'text-right', tdClass: 'text-right'  },
    ]

    const { $get } = useHttp()

    const {
      isBusy,
      itemLists,
      totalList,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    } = useListTable({ url: false })

    const columnToBeFilter = [
      'no_inv'
    ]
    let totalsPayment = ref(0)
    const dataDetails = ref([])


    const getDetails = async (id) => {
        totalsPayment.value = 0
        const { data } = await $get({ url: `reports/payment/Details/${id}`})
        dataDetails.value    = data

        for (let index = 0; index < dataDetails.value.length; index++) {
            totalsPayment.value = totalsPayment.value + parseFloat(dataDetails.value[index].pelunasan_uang) 
        }

    }

    return {
      totalsPayment,
      dataDetails,
      tableColumns,
      isBusy,
      perPage: 5,
      currentPage: 1,
      totalList,
      dataMeta,
      pageOptions: [3, 5, 10],
      searchQuery,
      sortBy,
      columnToBeFilter,
      isSortDirDesc,
      refListTable,
      statusFilter,
      getDetails,
      formatCurrency,
      ...useFilterReport({
        baseUrl: 'reports/payment/New',
        fetchLists
      }),
      itemLists,
      totalRows: itemLists,
    }
  },
  methods: {
     onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    exportToPDF () {
      this.$nextTick(() => this.getPdf())
    },
    exportToExcel () {
      import('@/vendors/Export2Excel').then(excel => {
        const list = this.itemLists

        // add title
        // const title = [this.$store.state.user.currentCompany.name, this.$t('apps.reportLedger.singular.ledgerReport'), this.periodReportText]
        const headerTitle = this.tableColumns.map(column => column.label)
        // only take indexData from columnDef,
        const headerVal = this.tableColumns.map(column => column.key)
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          multiHeader: [
            [this.$store.state.user.currentCompany.name, '', '', '', '', '', ''],
            ['Ledger Report', '', '', '', '', '', ''],
            [this.periodReportText, '', '', '', '', '', ''],
            ['', '', '', '', '', '', '']
          ],
          header: headerTitle,
          data,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 6, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 6, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 6, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 6, r: 3 } }
          ],
          filename: `Payment Report ${this.periodReportText}`,
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    }
  }
}
</script>

<style lang="scss">
#refListTable_remaks {
    width: inherit;
    margin: 0 50px 20px 100px;
    padding: 30px 0;

    thead {
      th {
        border-top: 2px solid #ebe9f1;
      }
      th:first-child {
        border-left: 2px solid #ebe9f1;
        border-radius: 4px;
      }

      th:last-child {
        border-right: 2px solid #ebe9f1;
        border-radius: 4px;
      }
    }

    td:first-child {
      border-left: 2px solid #ebe9f1;
    }
    td:last-child {
      border-right: 2px solid #ebe9f1;
    }

    tbody {
      tr:last-child {
        border-bottom: 2px solid #ebe9f1;
      }
    }

  }
</style>

